import React, { useState, useEffect } from "react";
import IconSelector from "./IconSelector";
import axios from 'axios';

function ConfirmationModal({ onCancel, onConfirm }) {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-md">
                <p>Are you sure? You're about to delete a habit.</p>
                <div className="flex justify-end mt-4">
                    <button onClick={onCancel} className="mr-2">No</button>
                    <button onClick={onConfirm} className="bg-red-500 text-white px-4 py-2 rounded">Yes</button>
                </div>
            </div>
        </div>
    );
}

function isValidFormat(str) {
    // Use regex to match the required format
    const match = str.match(/^(\d+)\/(\d+)$/);

    if (!match) return false; // If the format doesn't match, return false

    const a = parseInt(match[1], 10);
    const b = parseInt(match[2], 10);

    return a >= 0 && a <= b && b <= 14;
}

function EditHabit({ server_name, editHabit, current_habits, setEditHabit }) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [newHabit, setNewHabit] = useState(null);
    const [oldPos, setOldPos] = useState(current_habits[editHabit].pos);

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get(`${server_name}api/habits/${editHabit}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                setNewHabit(response.data);
            })
            .catch(error => {
                console.error('Error fetching habit:', error);
            });
    }, []);  // Assuming habitId is a prop or state that specifies which habit to fetch

    function updateHabitPositions() {
        const token = localStorage.getItem('token'); // Assuming you store the JWT in local storage.

        axios.put(`${server_name}api/updateHabitPositions/`, {}, {  // No need to send the habits since they are fetched directly from the backend.
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                console.log('Habit positions updated successfully:', response.data);
                // You might want to update your frontend state or UI here based on the response.
                window.location.reload();
            })
            .catch(error => {
                console.error('Error updating habit positions:', error);
                // Handle the error. For example, show a notification or an alert to the user.
            });
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        const token = localStorage.getItem('token');
        let new_habit = newHabit

        if (!isValidFormat(new_habit.type)) {
            new_habit.type = "0/7"
        }

        if (new_habit.pos < oldPos) {
            new_habit.pos -= 0.5
            console.log("decrease new habit pos to ", new_habit.pos)
        } else if (new_habit.pos > oldPos) {
            new_habit.pos += 0.5
            console.log("increase new habit pos to ", new_habit.pos)
        }
        setNewHabit(new_habit)

        // Send data to the API
        axios.put(`${server_name}api/habits/${editHabit}`, new_habit, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                console.log('Habit edited successfully:', response.data);
                // Close the form after successful submission
                if (oldPos !== new_habit.pos) {
                    updateHabitPositions();
                } else {
                    window.location.reload();
                }
                setEditHabit(null);
            })
            .catch(error => {
                console.error('Error editing habit:', error);
            });
    }

    function deleteHabit() {
        const token = localStorage.getItem('token');

        console.log(newHabit._id, " about to be deleted?")

        // Send data to the API
        axios.delete(`${server_name}api/habits`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: { habitName: newHabit._id }
        })
            .then(response => {
                console.log('Habit edited successfully:', response.data);
                // Close the form after successful submission

                console.log("have to change all position of current habits: ", current_habits)
                updateHabitPositions();
                setEditHabit(null);
            })
            .catch(error => {
                console.error('Error editing habit:', error);
            });
    }


    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
            {newHabit && <div className="bg-white p-6 rounded shadow-lg flex-none text-black relative">
                {/* Close button */}
                <button
                    onClick={() => setEditHabit(null)}
                    className="absolute -top-2 -left-2 bg-red-500 font-bold text-white pl-2 pr-2 pt-1 pb-1 rounded-full"
                >
                    X
                </button>
                <div className="pb-4 text-center text-xl">Attributes of your new habit</div>
                <form onSubmit={handleFormSubmit}>

                    <div className="m-1">What is your new habit?</div>

                    <input
                        type="text"
                        placeholder="Name"
                        value={newHabit.name}
                        onChange={e => setNewHabit(prev => ({ ...prev, name: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />

                    <div className="m-1">How many times per week is your goal? (e.g. 3/7 or 7/7) </div>
                    <input
                        type="text"
                        placeholder="X/7"
                        value={newHabit.type}
                        onChange={e => setNewHabit(prev => ({ ...prev, type: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />

                    <div className="m-1">What is this habit for?</div>
                    <input
                        type="text"
                        placeholder="I'm doing this because ..."
                        value={newHabit.reason}
                        onChange={e => setNewHabit(prev => ({ ...prev, reason: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />
                    <div className="flex w-full mt-2 mb-2">
                        <div className="m-1 mr-2">Habit active?</div>
                        <input
                            type="checkbox"
                            defaultChecked={newHabit.active}
                            onChange={e => setNewHabit(prev => ({ ...prev, active: e.target.checked }))}
                            className="block p-1 border-2 border-slate-200 rounded-md m-1"
                        />

                        <div className="m-1 mr-2 ml-12">Habit visible? </div>
                        <input
                            type="checkbox"
                            defaultChecked={newHabit.visible}
                            onChange={e => setNewHabit(prev => ({ ...prev, visible: e.target.checked }))}
                            className="block p-1 border-2 border-slate-200 rounded-md m-1"
                        />

                        <div className="m-1 mr-2 ml-12">Habits' position: </div>
                        <input
                            type="number"
                            min={1}
                            max={Object.keys(current_habits).length}
                            step={1}
                            defaultValue={newHabit.pos} // set the default value to the current position
                            onChange={e => setNewHabit(prev => ({ ...prev, pos: parseInt(e.target.value) }))}
                            className="block text-xs border-2 border-slate-200 rounded-md m-1"
                        />
                    </div>


                    <div className="m-1">Choose an icon:</div>
                    <IconSelector
                        server_name={server_name}
                        selectedIcon={newHabit.icon_path}
                        onSelect={iconPath => setNewHabit(prev => ({ ...prev, icon_path: iconPath }))}
                    />
                    {showDeleteConfirmation && (
                        <ConfirmationModal
                            onCancel={() => setShowDeleteConfirmation(false)}
                            onConfirm={() => {
                                deleteHabit();
                                setShowDeleteConfirmation(false);
                            }}
                        />
                    )}

                    {/* Repeat similar inputs for other habit attributes */}
                    <div className="flex">
                        <button type="submit" className="block border-2 w-full mr-8 rounded-xl mt-2 hover:bg-blue-500">Update Habit</button>

                        <button
                            type="button"
                            onClick={() => setShowDeleteConfirmation(true)}
                            className="block border-2 bg-red-500 w-full mr-8 rounded-xl mt-2 hover:bg-blue-500"
                        >
                            Delete Habit
                        </button>
                    </div>

                </form>
            </div>
            }
        </div>
    );
}

export default EditHabit;
