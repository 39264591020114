import React, { useState, useEffect } from "react";
import axios from 'axios';
import IconSelector from "./IconSelector";

function isValidFormat(str) {
    // Use regex to match the required format
    const match = str.match(/^(\d+)\/(\d+)$/);

    if (!match) return false; // If the format doesn't match, return false

    const a = parseInt(match[1], 10);
    const b = parseInt(match[2], 10);

    return a >= 0 && a <= b && b <= 14;
}

function AddHabit({ server_name, current_habits, showForm, setShowForm }) {
    const [newHabit, setNewHabit] = useState({
        _id: "",
        name: "",
        type: "0/7",
        active: true,
        visible: true,
        reason: "",
        pos: Object.keys(current_habits).length,
        creation_date: "",
        icon_path: ""
    });
    const [addedHabit, setAddedHabit] = useState(false)

    function formatString(str) {
        // Replace spaces with underscores
        let formatted = str.replace(/ /g, "_");

        // Convert to lowercase
        formatted = formatted.toLowerCase();

        // Remove any character that's not a-z or _
        formatted = formatted.replace(/[^a-z_]/g, '');

        return formatted;
    }

    function getCurrentDate() {
        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');  // Months are 0-11, hence the +1
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        let tempHabit = newHabit
        tempHabit._id = formatString(tempHabit.name)

        if (!isValidFormat(tempHabit.type)){
            tempHabit.type = "0/7"
        }

        tempHabit.creation_date = getCurrentDate();
        tempHabit.pos = Object.keys(current_habits).length
        setNewHabit(tempHabit)
        setAddedHabit(true)
    }

    useEffect(() => {
        console.log("here?", newHabit._id.length)
        if (newHabit._id.length > 0) {
            const token = localStorage.getItem('token');
            axios.post(server_name + 'api/habits', newHabit, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(response => {
                console.log('Habit added successfully:', response.data);
                // Close the form after successful submission
                setShowForm(false);
                window.location.reload();
            })
                .catch(error => {
                    console.error('Error adding habit:', error);
                });
        }
    }, [addedHabit])

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
            <div className="bg-white p-6 rounded shadow-lg flex-none text-black relative">
                {/* Close button */}
                <button
                    onClick={() => setShowForm(false)}
                    className="absolute -top-2 -left-2 bg-red-500 font-bold text-white pl-2 pr-2 pt-1 pb-1 rounded-full"
                >
                    X
                </button>
                <div className="pb-4 text-center text-xl">Attributes of your new habit</div>
                <form onSubmit={handleFormSubmit}>

                    <div className="m-1">What is your new habit?</div>

                    <input
                        type="text"
                        placeholder="Name"
                        value={newHabit.name}
                        onChange={e => setNewHabit(prev => ({ ...prev, name: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />

                    <div className="m-1">How many times per week is your goal? (e.g. 3/7 or 7/7) </div>
                    <input
                        type="text"
                        placeholder="X/7"
                        value={newHabit.type}
                        onChange={e => setNewHabit(prev => ({ ...prev, type: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />

                    <div className="m-1">What is this habit for?</div>
                    <input
                        type="text"
                        placeholder="I'm doing this because ..."
                        value={newHabit.reason}
                        onChange={e => setNewHabit(prev => ({ ...prev, reason: e.target.value }))}
                        className="block w-full p-1 mb-2 border-2 border-slate-200 rounded-md m-1"
                    />
                    <div className="flex w-full mt-2 mb-2">
                        <div className="m-1 mr-2">Habit active?</div>
                        <input
                            type="checkbox"
                            defaultChecked={newHabit.active}
                            value={newHabit.active}
                            onChange={e => setNewHabit(prev => ({ ...prev, active: e.target.value }))}
                            className="block p-1 border-2 border-slate-200 rounded-md m-1"
                        />

                        <div className="m-1 mr-2 ml-12">Habit visible? </div>
                        <input
                            type="checkbox"
                            defaultChecked={newHabit.visible}
                            value={newHabit.visible}
                            onChange={e => setNewHabit(prev => ({ ...prev, visible: e.target.value }))}
                            className="block p-1 border-2 border-slate-200 rounded-md m-1"
                        />
                    </div>


                    <div className="m-1">Choose an icon:</div>
                    <IconSelector
                        server_name={server_name}
                        selectedIcon={newHabit.icon_path}
                        onSelect={iconPath => setNewHabit(prev => ({ ...prev, icon_path: iconPath }))}
                    />

                    {/* Repeat similar inputs for other habit attributes */}
                    <button type="submit" className="block border-2 w-full mr-8 rounded-xl mt-2 hover:bg-blue-500">Add new Habit</button>
                </form>
            </div >
        </div >
    );
}

export default AddHabit;
