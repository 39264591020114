import React, { useEffect, useState } from "react";
import AddHabit from "./AddHabit";
import EditHabit from "./EditHabit";
import HoverMessage from "./HoverMessage";

function TableHeader({ habits, habitStatuses, server_name, user, onEditTable, setEditHabit, setShowFormToAddHabit }) {
    const [locked, setLocked] = useState(true);

    useEffect(()=> {
        onEditTable(locked)
    }, [locked])

    function titleCase(str) {
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    
    return (

        <thead>
            <tr>

                {user &&
                    <th className="relative pr-7 pl-7 text-lg">
                        <div className="absolute -top-2 -left-4 z-10">
                        <HoverMessage message="Edit your Habit Board">
                            <img
                                src={server_name + "symbols/zahnrad_white.png"}
                                alt="edit"
                                title="Unlock Habits"
                                onClick={() => setLocked(!locked)}
                                className={`transform hover:cursor-pointer scale-50 ${locked ? "hover:bg-blue-500" : "bg-blue-500 hover:bg-slate-700"}`}  // This scales the image to 75% of its original size
                            />
                            </HoverMessage>
                        </div>
                        <div>Habit Board</div>
                    </th>}
                {Object.keys(habits).slice(1).map((habit, index) => {
                      
                    if (!habits[habit].active && locked) return null; // Skip rendering if habit is not active

                    return (
                        <th key={index} className={`relative`}>
                            <HoverMessage 
                            message={`${!habits[habit].active ? `${habits[habit].name} (inactive)` 
                            : `${habits[habit].name} (${habits[habit].type}) | ${habits[habit].reason}`}`} >
                            {!locked &&
                                <div className="absolute -top-2 -left-4 z-10">
                                    <img
                                        src={server_name + "symbols/zahnrad_white.png"}
                                        alt="edit"
                                        title="Edit Habits"
                                        onClick={() => setEditHabit(habit)}
                                        className={`hover:bg-blue-500 transform scale-50 hover:cursor-pointer`}
                                    />
                                </div>}

                            <img
                                src={server_name + `icons/${habits[habit].icon_path}`}
                                onError={(e) => { e.target.onerror = null; e.target.src = server_name + "icons/default.png"; }}
                                alt={habits[habit].name}
                                className={`${!habits[habit].active ? "bg-slate-700" : ""}`}
                            />
                            </HoverMessage>
                            <div className={`pt-2 border-b-8 border-${habitStatuses[habit]}`}></div>
                        </th>
                    )
                })}

                {!locked && <th className="mt-1 ml-1 absolute">
                    <HoverMessage message="Add a new habit!">
                    <img
                        src={server_name + "symbols/plus-icon.png"}
                        alt="edit"
                        title="Add Habits"
                        className={`transform hover:bg-blue-500 hover:cursor-pointer`}
                        onClick={() => setShowFormToAddHabit(true)}
                    />
                    </HoverMessage>
                </th>}

            </tr>
        </thead>

    );
}

export default TableHeader;
