import React from "react";

function StartPage() {
  return (
    <div className="pl-2 text-white">
        Some information on startpage
    </div>
  );
}

export default StartPage;
