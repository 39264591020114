import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import StartPage from './components/StartPage';
import HabitTracker from "./components/HabitTracker";

const server_name = "https://habitskool.com/node/";//"http://h2971102.stratoserver.net:1234/" //
//const server_name = "https://christophsaffer.com/main/node/" // "http://localhost:3001/" //

function isTokenExpired(token) {
  try {
      const decodedToken = jwt_decode(token);
      // Check if the expiration time is before the current time
      return decodedToken.exp < Date.now() / 1000;
  } catch (error) {
      console.error('Error decoding token:', error);
      return true; // if there's an error decoding, assume it's expired
  }
}

function App() {
  const [user, setUser] = useState(null);
  const [siteArea, setSiteArea] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const userFirstName = localStorage.getItem('userfirstname');

    if (token && username) {
      // User is logged in, set the user state
      setUser(username);
      setUserFirstName(userFirstName);
      setSiteArea("userarea");
    }
  }, []);

  function logout() {
    // Clear user data from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userfirstname')

    // Clear user state
    setUser(null);
    setSiteArea(null);
    setUserFirstName(null);

  }

  function verifyToken() {
    const token = localStorage.getItem('token');
  
    if (!token || isTokenExpired(token)) {
        logout();
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
        verifyToken();
    }, 6000000);  // 6,000,000 milliseconds is 100 minutes

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
}, []);


  function login(event) {
    event.preventDefault();

    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    axios.post(server_name + 'api/login', { username, password })
      .then(response => {
        // Store token in localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.user);
        localStorage.setItem('userfirstname', response.data.firstname);
        setUser(response.data.user);
        setUserFirstName(response.data.firstname);
        setSiteArea("userarea")
      })
      .catch(error => {
        console.error('Error logging in', error);
      });
  }

  return (
    <div className="App">
      <div className="bg-black h-screen">
        <div className="flex">
          <div>
            <img src="logo3.png" alt="Your Logo" className="h-24 w-auto" /> {/* Adjust the h-12 to the height you want */}
          </div>
          <div className="ml-auto">
            {!user &&
              <div className="">
                <form onSubmit={login}>
                  <input type="text" placeholder="username" id="username" className="bg-black text-white m-2 p-1 w-24" />
                  <input type="password" placeholder="password" id="password" className="bg-black text-white m-2 p-1 w-24" />
                  <button type="submit" className="text-white hover:bg-blue-500 p-2 rounded-lg m-1">Login</button>
                </form>
              </div>
            }
            {user &&
              <button onClick={logout} className="text-white hover:bg-blue-500 p-2 rounded-lg m-1">Logout</button>
            }
          </div>

        </div>

        {userFirstName && <div className="m-4 mt-10 absolute text-white text-2xl">Hello {userFirstName}, what have you accomplished today?</div>}
        <Router>
          {(user && (siteArea == "userarea")) ? <Navigate to={`/${user}`} /> : <Navigate to="/" />}
            <Routes>
                <Route path="/" element={<StartPage />} />
                {/* If the user is authenticated, show the UserArea, otherwise navigate back to the root */}
                <Route path="/:user" element={user ? <HabitTracker user={user} server_name={server_name} /> : <Navigate to="/" />} />
            </Routes>
        </Router>

      </div>
    </div>
  );
}

export default App;
