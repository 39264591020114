import React, { useState, useEffect } from "react";
import axios from 'axios';

function IconSelector({ server_name, selectedIcon, onSelect }) {
    const [icons, setIcons] = useState([]);

    useEffect(() => {
        // Fetch available icons
        const token = localStorage.getItem('token');

        axios.get(server_name + 'icons/', {
          headers: {
              Authorization: 'Bearer ' + token
          }
      })
            .then(response => {
                setIcons(response.data);
            })
            .catch(error => {
                console.error('Error fetching icons:', error);
            });
    }, []);

    return (
      <div className="mt-2 mb-2 overflow-y-auto max-h-48 border-gray-100 border-2">
      {Array(Math.ceil(icons.length / 15)).fill().map((_, rowIndex) => (
          <div key={rowIndex} className="flex h-12 m-2">
              {icons.slice(rowIndex * 15, rowIndex * 15 + 15).map(iconPath => (
                  <img
                      key={iconPath}
                      src={server_name + "icons/" + iconPath}
                      alt="icon"
                      className={selectedIcon === iconPath ? 'selected bg-green-200 -m-1 border-black border-2' : 'm-1'}
                      onClick={() => onSelect(iconPath)}
                  />
              ))}
          </div>
      ))}
  </div>
    );
}

export default IconSelector;