import React, { useState, useEffect } from "react";
import axios from 'axios';
import Habit from "./Habit";
import './HabitTracker.css';  // import the CSS file
import TableHeader from "./TableHeader";
import HoverMessage from "./HoverMessage";
import EditHabit from "./EditHabit";
import AddHabit from "./AddHabit";

function getNameOfDay(day) {
  if (day == 0) {
    return "Sunday"
  } else if (day == 1) {
    return "Monday"
  } else if (day == 2) {
    return "Tuesday"
  } else if (day == 3) {
    return "Wednesday"
  } else if (day == 4) {
    return "Thursday"
  } else if (day == 5) {
    return "Friday"
  } else {
    return "Saturday"
  }
}

function calculateHabitStatuses(updatedRecords, habits) {
  const newHabitStatuses = {};

  // Filter dates where cheat_day is false and get the last 7 days
  const sortedDates = Object.keys(updatedRecords.days)
    .filter((date) => !updatedRecords.days[date]["cheat_day"])
    .sort((a, b) => new Date(b) - new Date(a))
    .slice(0, 7);

  console.log(sortedDates)

  Object.keys(habits).forEach((habit) => {
    const goal = parseInt(habits[habit].type.split("/")[0]);
    const total = sortedDates.reduce((sum, date) => sum + (updatedRecords.days[date][habit] ? 1 : 0), 0);
    console.log(habit, total, goal)
    if (total >= goal) {
      console.log(habit, " bcomes green")
      newHabitStatuses[habit] = "green";
    } else if (total >= goal - 1) {
      console.log(habit, " bcomes orange")
      newHabitStatuses[habit] = "orange";
    } else {
      console.log(habit, " bcomes red")
      newHabitStatuses[habit] = "red";
    }
  });

  return newHabitStatuses;
}

const statusDay = (days, habits) => {
  const weightedTrueHabits = Object.keys(days)
    .filter((habit) => habits[habit] && habits[habit].active)
    .reduce((acc, habit) => {
      const [numerator, denominator] = habits[habit].type.split('/').map(Number);
      acc.weightedHabits += numerator / denominator;
      if (days[habit]) {
        acc.weightedTrueHabits += numerator / denominator;
      }
      return acc;
    }, { weightedHabits: 0, weightedTrueHabits: 0 });
  return (weightedTrueHabits.weightedTrueHabits / (weightedTrueHabits.weightedHabits + 0.00001)) * 100;
}

const colorBasedOnPercentage = (percentage) => {
  if (percentage >= 75) return 'text-green-500';
  if (percentage <= 25) return 'text-red-500';
  return 'text-white';
};

const statusBasedOnPercentage = (percentage) => {
  if (percentage >= 75) return 'Goals reached. Great day!';
  if (percentage <= 25) return 'Day has still potential!';
  return 'On the right track!';
}

function HabitTracker({ user, server_name }) {
  const [habits, setHabits] = useState({});
  const [records, setRecords] = useState({ days: {} });
  const [nRecords, setNRecords] = useState(7);
  const [habitStatuses, setHabitStatuses] = useState({});
  const [editTable, setEditTable] = useState(false);
  const [editHabit, setEditHabit] = useState(null);
  const [showFormToAddHabit, setShowFormToAddHabit] = useState(false);

  useEffect(() => {
    let newHabitStatuses = { ...habitStatuses };
    Object.keys(habits).forEach((habit) => {
      let count = 0;
      lastXRecordsDates.forEach((date) => {
        if (records.days[date] && records.days[date][habit]) count += 1;
      });

      if (count >= habits[habit].type.split("/")[0]) {
        newHabitStatuses[habit] = "green";
      } else if (count === habits[habit].type.split("/")[0] - 1) {
        newHabitStatuses[habit] = "orange";
      } else {
        newHabitStatuses[habit] = "red";
      }
    });

    setHabitStatuses(newHabitStatuses);
  }, [records]);


  useEffect(() => {
    // Get the habits data

    const token = localStorage.getItem('token');

    axios.get(server_name + 'api/habits', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        const transformedData = response.data.reduce((acc, cur) => {
          const { _id, ...rest } = cur;
          acc.habits[_id] = rest;
          return acc;
        }, { habits: {} });
        // Transforming habits object into an array
        const habitsArray = Object.entries(transformedData.habits)
          .map(([id, habit]) => ({ id, ...habit }))
          .sort((a, b) => a.pos - b.pos); // Sorting based on the pos property

        // Transforming sorted array back into an object
        const sortedHabits = habitsArray.reduce((acc, habit) => {
          acc[habit.id] = habit;
          delete acc[habit.id].id; // Removing id from each habit as it's already a key
          return acc;
        }, {});

        console.log("transformed data: ", sortedHabits);
        setHabits(sortedHabits);
      })
      .catch(error => {
        console.error('Error fetching habits', error);
      });



    // Get the habit records data
    axios.get(server_name + 'api/habit-records', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(response => {
      const transformedData = response.data.reduce((acc, cur) => {
        const { _id, ...rest } = cur;
        acc.days[_id] = rest;
        return acc;
      }, { days: {} });

      setRecords(transformedData);
      const newHabitStatuses = calculateHabitStatuses(transformedData, habits);
      setHabitStatuses(newHabitStatuses);
    })
      .catch(error => {
        console.error('Error fetching habit records', error);
      });
  }, [user]);

  function onHabitChange(date, habit) {
    // Toggle the status of the habit
    const updatedRecords = { ...records };
    updatedRecords.days[date] = updatedRecords.days[date] ?? {};
    updatedRecords.days[date][habit] = !updatedRecords.days[date][habit];
    setRecords(updatedRecords);

    // Calculate the habit status and update the habitStatuses state
    const newHabitStatuses = calculateHabitStatuses(updatedRecords, habits);
    setHabitStatuses(newHabitStatuses);
    // Update the habit records data on the server
    const token = localStorage.getItem('token');

    axios.put(`${server_name}api/habit-records/${date}/${habit}`, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        console.log(response.data.message);
      })
      .catch(error => {
        console.error('Error updating habit records', error);
      });
  }


  // Sort records by date
  const sortedRecordsDates = Object.keys(records.days).sort((a, b) => new Date(b) - new Date(a));

  // Only keep the last 7 records
  const lastXRecordsDates = sortedRecordsDates.slice(0, nRecords);

  const showMore = () => {
    setNRecords(prevRecords => prevRecords + 7);
  };

  const showLess = () => {
    setNRecords(7);
  };

  return (
    <div className="">

      {editHabit &&
        <EditHabit
          server_name={server_name}
          editHabit={editHabit}
          current_habits={habits}
          setEditHabit={setEditHabit}
        />
      }

      {showFormToAddHabit &&
        <AddHabit
          server_name={server_name}
          current_habits={habits}
          showForm={showFormToAddHabit}
          setShowForm={setShowFormToAddHabit}
        />
      }

      <table className="habit-tracker">
        <TableHeader
          habits={habits}
          habitStatuses={habitStatuses}
          server_name={server_name}
          user={user}
          onEditTable={(locked) => setEditTable(!locked)}
          setEditHabit={setEditHabit}
          setShowFormToAddHabit={setShowFormToAddHabit}
        />

        <tbody> 
          <div className="overflow-y-auto max-h-96">
          {lastXRecordsDates.map((date, index) => {
            // Extract the day and month from the date
            const [year, month, day] = date.split("-");
            const formattedDate = `${day}/${month}/${year}`;

            // Create a Date object from the date string and get the day of the week
            const dateObj = new Date(year, month - 1, day);
            const dayOfWeek = dateObj.getDay();

            // Determine if the day is a Saturday or Sunday
            const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

            const truePercentage = statusDay(records.days[date], habits)
            const newColor = colorBasedOnPercentage(truePercentage)

            return (
              <tr key={index}>
                <td>
                  <HoverMessage message={getNameOfDay(dayOfWeek) + " | " + statusBasedOnPercentage(truePercentage) }>
                  <div className={`${newColor} ${isWeekend ? 'underline' : ''}`}>
                    {formattedDate}
                  </div>
                  </HoverMessage>
                  {editTable && <div className="absolute -m-6 pl-1">
                    <HoverMessage message="Cheat day! Today does not count if activated.">
                      <input
                        type="checkbox"
                        checked={(records.days[date] && records.days[date]["cheat_day"]) || false}
                        onChange={() => onHabitChange(date, "cheat_day")}
                        disabled={!user} // Disable if not logged in
                        className="h-2"
                        alt="Cheat Day!"
                      />
                    </HoverMessage>
                  </div>}
                </td>
                {Object.keys(habits).slice(1).map((habit, index) => {
                  if (!habits[habit].active && !editTable) return null; // Skip rendering if habit is not active

                  return (
                    <td key={index} className={`${records.days[date]["cheat_day"] ? "bg-slate-700 mt-4" : "bg-black"} `}>
                      <div className="pl-1">
                        <input
                          type="checkbox"
                          checked={(records.days[date] && records.days[date][habit]) || false}
                          onChange={() => onHabitChange(date, habit)}
                          disabled={editTable || !user || records.days[date]["cheat_day"]} // Disable if not logged in
                        />
                      </div>
                    </td>
                  );
                })}

              </tr>
            );
          })}
          <div className="text-xs ml-3 w-24">
            <button className="m-1 p-1 hover:bg-blue-500 rounded-md" onClick={showMore}>Show more</button>
            {nRecords > 7 && <button className="m-1 p-1 hover:bg-blue-500 rounded-md" onClick={showLess}>Show less</button>}
          </div>
          </div>
        </tbody>
      </table>
    </div>
  );

}

export default HabitTracker;
