import React, { useState } from 'react';

const HoverMessage = ({ message, children }) => {
  const [isHovering, setHovering] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isHovering && (
        <div className="absolute w-max text-xs -mt-2 ml-4 p-1 text-black top-full left-0 bg-gray-200 border border-gray-300 z-10 transition-opacity duration-300">
          {message}
        </div>
      )}
    </div>
  );
};

export default HoverMessage;
